import { Reducer } from 'redux';

import { BlogPost } from 'models/BlogPost';

import {
  BlogPostsActions,
  BLOG_POSTS_GET_REQUEST,
  BLOG_POSTS_GET_SUCCESS,
  BLOG_POST_DELETE_REQUEST,
  BLOG_POST_DELETE_SUCCESS,
} from 'actions/blog_posts/types';
import { ResponseDataState } from './rootReducer';

export const blogPosts: Reducer<ResponseDataState, BlogPostsActions> = (
  state = {
    data: [],
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case BLOG_POST_DELETE_REQUEST:
    case BLOG_POSTS_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BLOG_POSTS_GET_SUCCESS:
      return {
        ...state,
        data: action.response.data.map(attributes => new BlogPost(attributes)),
        isLoading: false,
      };
    case BLOG_POST_DELETE_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data.filter(
            (blogPost: BlogPost) => action.id !== blogPost.id()
          ),
        ],
        isLoading: false,
      };

    default: {
      return state;
    }
  }
};
