import { FaqAttributes } from 'actions/faqs/types';

export class Faq {
  readonly data: FaqAttributes;

  constructor(data: FaqAttributes) {
    this.data = data;
  }

  id() {
    return this.data.id;
  }

  title() {
    return this.data.title;
  }

  description() {
    return this.data.description;
  }

  priority() {
    return this.data.priority;
  }
}
