import { Reducer } from 'redux';

import { TranslationItemActions } from 'actions/translationsActions';

interface TranslationItemState {
  loading: boolean;
}

const initialState = { loading: false };

export const translationItem: Reducer<
  TranslationItemState,
  TranslationItemActions
> = (state = initialState, action) => {
  switch (action.type) {
    case 'TRANSLATION_ITEM_UPDATE_REQUEST': {
      return {
        ...state,
        loading: true
      };
    }
    case 'TRANSLATION_ITEM_UPDATE_SUCCESS': {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      // return nothing
    }
  }

  return state;
};
