import { Action, Dispatch, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import api from 'actions/utils/api';
import { requestStart, requestSuccess } from 'actions/utils/requestActions';
import { AppState } from 'reducers/rootReducer';
import { CharacterOpinionTemplateFormParams } from 'components/admin/character_opinion_templates/CharacterOpinionTemplateForm';
import { addNotificationMessage } from './notificationMessagesActions';

import { PaginationHeaders } from './translationsActions';

interface CharacterType {
  id: number;
  code: string;
  fullName: string;
}

export interface CharacterOpinionTemplate {
  id: number;
  updatedAt: string;
  isActive: boolean;
  isVisibleForAll: boolean;
  isForMale: boolean;
  isEditable: boolean;
  ageFrom: number;
  ageTo: number;
  name: string;
  order: number;
  content: string;
  languageId: number;
  characterType: CharacterType;
  characterTypeCode: string;
  isFree: boolean;
  [key: string]: string | boolean | number | CharacterType;
}

export type CharacterOpinionTemplatesActions =
  | CharacterOpinionTemplatesGetRequestAction
  | CharacterOpinionTemplateRemove
  | CharacterOpinionTemplatesGetSuccessAction;

export type CharacterOpinionTemplateActions =
  | CharacterOpinionTemplateGetRequestAction
  | CharacterOpinionTemplateGetSuccessAction
  | CharacterOpinionTemplatePutRequestAction
  | CharacterOpinionTemplatePutSuccessAction
  | CharacterOpinionTemplatePostSuccessAction
  | CharacterOpinionTemplatePostRequestAction
  | CharacterOpinionTemplateUnsubscribe
  | CharacterOpinionTemplateDeleteRequestAction
  | CharacterOpinionTemplateDeleteSuccessAction;

export const CHARACTER_OPINION_TEMPLATES_GET_REQUEST =
  'CHARACTER_OPINION_TEMPLATES_GET_REQUEST';
export const CHARACTER_OPINION_TEMPLATES_GET_SUCCESS =
  'CHARACTER_OPINION_TEMPLATES_GET_SUCCESS';

export interface CharacterOpinionTemplatesGetRequestAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATES_GET_REQUEST> {
  loading: boolean;
}

export interface CharacterOpinionTemplatesGetSuccessAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATES_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: CharacterOpinionTemplate[];
    headers: PaginationHeaders;
  };
}

export function getAllCharacterOpinionTemplates(
  page: number,
  query?: {
    characterTypeId?: number;
    languageId?: number;
  }
): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart(CHARACTER_OPINION_TEMPLATES_GET_REQUEST));

    let searchParams = {};

    if (query) {
      const { characterTypeId, languageId } = query;

      searchParams = {
        ...(characterTypeId && {
          characterTypeId,
        }),
        ...(languageId && { languageId }),
      };
    }

    try {
      const response = await api.get('admin/character_opinion_templates', {
        page,
        ...searchParams,
      });
      dispatch(
        requestSuccess(CHARACTER_OPINION_TEMPLATES_GET_SUCCESS)(response)
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export const CHARACTER_OPINION_TEMPLATE_PUT_REQUEST =
  'CHARACTER_OPINION_TEMPLATE_PUT_REQUEST';
export const CHARACTER_OPINION_TEMPLATE_PUT_SUCCESS =
  'CHARACTER_OPINION_TEMPLATE_PUT_SUCCESS';

export interface CharacterOpinionTemplatePutRequestAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_PUT_REQUEST> {
  loading: boolean;
}

export interface CharacterOpinionTemplatePutSuccessAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_PUT_SUCCESS> {
  loading: boolean;
}

export function updateCharacterOpinionTemplate(
  id: number,
  params: Partial<CharacterOpinionTemplateFormParams>
): ThunkAction<void, AppState, null, AnyAction> {
  return async (dispatch: ThunkDispatch<AppState, null, AnyAction>) => {
    function onSuccess(response: any) {
      dispatch(
        requestSuccess(CHARACTER_OPINION_TEMPLATE_PUT_SUCCESS)(response)
      );

      return response;
    }

    try {
      dispatch(requestStart(CHARACTER_OPINION_TEMPLATE_PUT_REQUEST));

      const response = await api.put(
        `admin/character_opinion_templates/${id}`,
        params
      );

      await onSuccess(response);
      dispatch(getCharacterOpinionTemplate(id));

      dispatch(
        addNotificationMessage({
          type: 'success',
          text: 'Pomyślnie zaktualizowano rozdział!',
        })
      );
    } catch (error) {
      console.log(error);

      dispatch(
        addNotificationMessage({
          type: 'error',
          text: 'Wystąpił błąd!',
        })
      );
    }
  };
}

export const CHARACTER_OPINION_TEMPLATE_GET_REQUEST =
  'CHARACTER_OPINION_TEMPLATE_GET_REQUEST';
export const CHARACTER_OPINION_TEMPLATE_GET_SUCCESS =
  'CHARACTER_OPINION_TEMPLATE_GET_SUCCESS';

export interface CharacterOpinionTemplateGetRequestAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_GET_REQUEST> {
  loading: boolean;
}

export interface CharacterOpinionTemplateGetSuccessAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: CharacterOpinionTemplate;
  };
}

export function getCharacterOpinionTemplate(
  id: string | number
): ThunkAction<void, AppState, null, AnyAction> {
  return async (dispatch: ThunkDispatch<AppState, null, AnyAction>) => {
    dispatch(requestStart(CHARACTER_OPINION_TEMPLATE_GET_REQUEST));

    try {
      const response = await api.get(`admin/character_opinion_templates/${id}`);

      dispatch(
        requestSuccess(CHARACTER_OPINION_TEMPLATE_GET_SUCCESS)(response)
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export interface CharacterOpinionTemplateUnsubscribe
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_UNSUBSCRIBE> {
  loading: boolean;
  data: [];
}

export const CHARACTER_OPINION_TEMPLATE_UNSUBSCRIBE =
  'CHARACTER_OPINION_TEMPLATE_UNSUBSCRIBE';

export function unsubscribeCharacterOpinionTemplate() {
  return {
    type: CHARACTER_OPINION_TEMPLATE_UNSUBSCRIBE,
  };
}

export const CHARACTER_OPINION_TEMPLATE_POST_REQUEST =
  'CHARACTER_OPINION_TEMPLATE_POST_REQUEST';
export const CHARACTER_OPINION_TEMPLATE_POST_SUCCESS =
  'CHARACTER_OPINION_TEMPLATE_POST_SUCCESS';

export const REMOVE_CHARACTER_OPINION_TEMPLATE =
  'REMOVE_CHARACTER_OPINION_TEMPLATE';

export interface CharacterOpinionTemplateRemove
  extends Action<typeof REMOVE_CHARACTER_OPINION_TEMPLATE> {
  loading: boolean;
  id: number;
}

export interface CharacterOpinionTemplatePostRequestAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_POST_REQUEST> {
  loading: boolean;
}

export interface CharacterOpinionTemplatePostSuccessAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_POST_SUCCESS> {
  loading: boolean;
}

export function createCharacterOpinionTemplate(
  params: CharacterOpinionTemplateFormParams
): ThunkAction<void, AppState, null, AnyAction> {
  return async (dispatch: ThunkDispatch<AppState, null, AnyAction>) => {
    function onSuccess(response: any) {
      dispatch(
        requestSuccess(CHARACTER_OPINION_TEMPLATE_POST_SUCCESS)(response)
      );
      return response;
    }

    try {
      dispatch(requestStart(CHARACTER_OPINION_TEMPLATE_POST_REQUEST));

      const response = await api.post(
        'admin/character_opinion_templates',
        params
      );

      await onSuccess(response);

      dispatch(
        addNotificationMessage({
          type: 'success',
          text: 'Pomyślnie dodano nowy rozdział',
        })
      );
    } catch (error) {
      console.log(error);

      dispatch(
        addNotificationMessage({
          type: 'error',
          text: 'Błąd!',
        })
      );
    }
  };
}

export const CHARACTER_OPINION_TEMPLATE_DELETE_REQUEST =
  'CHARACTER_OPINION_TEMPLATE_DELETE_REQUEST';
export const CHARACTER_OPINION_TEMPLATE_DELETE_SUCCESS =
  'CHARACTER_OPINION_TEMPLATE_DELETE_SUCCESS';

export interface CharacterOpinionTemplateDeleteRequestAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_DELETE_REQUEST> {
  loading: boolean;
}

export interface CharacterOpinionTemplateDeleteSuccessAction
  extends Action<typeof CHARACTER_OPINION_TEMPLATE_DELETE_SUCCESS> {
  loading: boolean;
}

export function deleteCharacterOpinionTemplate(
  id: number
): ThunkAction<void, AppState, null, AnyAction> {
  return async (dispatch: ThunkDispatch<AppState, null, AnyAction>) => {
    function removeItemFromGlobalState() {
      dispatch({
        type: REMOVE_CHARACTER_OPINION_TEMPLATE,
        id,
      });
    }

    function onSuccess(response: any) {
      dispatch(
        requestSuccess(CHARACTER_OPINION_TEMPLATE_DELETE_SUCCESS)(response)
      );

      return response;
    }

    try {
      dispatch(requestStart(CHARACTER_OPINION_TEMPLATE_DELETE_REQUEST));

      const response = await api.delete(
        `admin/character_opinion_templates/${id}`
      );

      await onSuccess(response);
      removeItemFromGlobalState();

      dispatch(
        addNotificationMessage({
          type: 'success',
          text: 'Pomyślnie usunięto rozdział opinii',
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addNotificationMessage({
          type: 'error',
          text: 'Wystąpił błąd! Usuwanie nie powiodło się!',
        })
      );
    }
  };
}
