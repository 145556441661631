exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-broszura-tsx": () => import("./../../../src/pages/broszura.tsx" /* webpackChunkName: "component---src-pages-broszura-tsx" */),
  "component---src-pages-certyfikacja-tsx": () => import("./../../../src/pages/certyfikacja.tsx" /* webpackChunkName: "component---src-pages-certyfikacja-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-mapadoradcow-tsx": () => import("./../../../src/pages/mapadoradcow.tsx" /* webpackChunkName: "component---src-pages-mapadoradcow-tsx" */),
  "component---src-pages-raport-tsx": () => import("./../../../src/pages/raport.tsx" /* webpackChunkName: "component---src-pages-raport-tsx" */),
  "component---src-pages-testobrazkowy-tsx": () => import("./../../../src/pages/testobrazkowy.tsx" /* webpackChunkName: "component---src-pages-testobrazkowy-tsx" */)
}

