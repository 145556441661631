import { decamelizeKeys } from 'humps';

import { Reducer } from 'redux';
import {
  I18nActions,
  TRANSLATIONS_SUBSCRIPTION_REQUEST,
  TRANSLATIONS_SUBSCRIPTION_SUCCESS,
  TRANSLATIONS_UNSUBSCRIBE,
} from 'actions/i18nActions';

interface I18nInstance {
  [key: string]: string;
}

interface i18nState {
  readonly data: I18nInstance;
  readonly loading: boolean;
}

export const initialI18nState = {
  data: {},
  loading: true,
};

export const i18n: Reducer<i18nState, I18nActions> = (
  state = initialI18nState,
  action
) => {
  switch (action.type) {
    case TRANSLATIONS_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case TRANSLATIONS_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: decamelizeKeys(action.response.data),
      };
    }

    case TRANSLATIONS_UNSUBSCRIBE: {
      return initialI18nState;
    }

    default: {
      // return nothing
    }
  }

  return state;
};
