import { Action, Dispatch, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import api from 'actions/utils/api';
import { requestStart, requestSuccess } from 'actions/utils/requestActions';
import { AppState } from 'reducers/rootReducer';

export const VARIABLES_GET_REQUEST = 'VARIABLES_GET_REQUEST';
export const VARIABLES_GET_SUCCESS = 'VARIABLES_GET_SUCCESS';

export interface VariablesGetRequestAction
  extends Action<typeof VARIABLES_GET_REQUEST> {
  loading: true;
}

export interface VariablesGetSuccessAction
  extends Action<typeof VARIABLES_GET_SUCCESS> {
  response: { data: string[] };
  loading: false;
}

export type VariablesActions =
  | VariablesGetRequestAction
  | VariablesGetSuccessAction;

type Variables = 'character_opinion';

export function getVariablesFor(
  resource: Variables
): ThunkAction<void, AppState, null, Action<VariablesActions>> {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart(VARIABLES_GET_REQUEST));

    try {
      const response = await api.get(`admin/variables/${resource}`);

      dispatch(requestSuccess(VARIABLES_GET_SUCCESS)(response));
    } catch (error) {
      console.log(error);
    }
  };
}
