interface UserAttributes {
  login?: string;
  email?: string;
  type?: string;
}

export class User {
  readonly data: UserAttributes;

  constructor(data: UserAttributes) {
    this.data = data;
  }

  login() {
    return this.data?.login;
  }

  email() {
    return this.data?.email;
  }

  type() {
    return this.data?.type;
  }
}
