import { Reducer } from 'redux';

import {
  CharacterType,
  CharacterTypesActions,
  CHARACTER_TYPES_GET_REQUEST,
  CHARACTER_TYPES_GET_SUCCESS,
} from 'actions/characterTypesActions';

interface CharacterTypesState {
  readonly data: CharacterType[];
  readonly loading: boolean;
}

const initialCharacterTypesState: CharacterTypesState = {
  data: [],
  loading: false,
};

export const characterTypes: Reducer<
  CharacterTypesState,
  CharacterTypesActions
> = (state = initialCharacterTypesState, action) => {
  switch (action.type) {
    case CHARACTER_TYPES_GET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHARACTER_TYPES_GET_SUCCESS: {
      return {
        ...state,
        data: action.response.data,
        loading: false,
      };
    }
    default: {
      // return nothing
    }
  }

  return state;
};
