import { Action } from 'redux';

export const ADD_NOTIFICATION_MESSAGE = 'ADD_NOTIFICATION_MESSAGE';
export const REMOVE_NOTIFICATION_MESSAGE = 'REMOVE_NOTIFICATION_MESSAGE';
export const SET_NOTIFICATION_DISPLAYED = 'SET_NOTIFICATION_DISPLAYED';

export type NotificationTypes = 'success' | 'errror' | 'warning';

export interface AddNotificationMessageAction
  extends Action<typeof ADD_NOTIFICATION_MESSAGE> {
  message: {
    type: string;
    text: string;
  };
}

export interface RemoveNotificationMessageAction
  extends Action<typeof REMOVE_NOTIFICATION_MESSAGE> {
  id: string;
}

export interface SetNotificationMessageDisplayedAction
  extends Action<typeof SET_NOTIFICATION_DISPLAYED> {
  id: string;
}

export type NotificationMessagesActions =
  | AddNotificationMessageAction
  | RemoveNotificationMessageAction
  | SetNotificationMessageDisplayedAction;

interface NotificationMessage {
  type: string;
  text: string;
}

export function setNotificationMassegeDisplayed(id: string) {
  return {
    type: SET_NOTIFICATION_DISPLAYED,
    id,
  };
}

export function addNotificationMessage(message: NotificationMessage) {
  return {
    type: ADD_NOTIFICATION_MESSAGE,
    message,
  };
}

export function removeNotificationMessage(id: string) {
  return {
    type: REMOVE_NOTIFICATION_MESSAGE,
    id,
  };
}
