import { Reducer } from 'redux'

import {
  CharacterExamActions,
  CharacterExamQuestion,
  CHARACTER_EXAM_QUESTIONS_GET_REQUEST,
  CHARACTER_EXAM_QUESTIONS_GET_SUCCESS,
} from 'actions/characterExamActions'

export interface CharacterExamState {
  readonly questions: CharacterExamQuestion[]
  readonly loading: boolean
}

const initialState = {
  questions: [],
  loading: false,
}

export const characterExam: Reducer<
  CharacterExamState,
  CharacterExamActions
> = (state = initialState, action) => {
  switch (action.type) {
    case CHARACTER_EXAM_QUESTIONS_GET_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case CHARACTER_EXAM_QUESTIONS_GET_SUCCESS: {
      return {
        ...state,
        questions: action.response.data,
        loading: false,
      }
    }
    default: {
      // return nothing
    }
  }

  return state
}
