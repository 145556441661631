import { Reducer } from 'redux';

import { User } from 'models/User';

import {
  LOGIN_USER_REQUEST_START,
  LOGOUT_USER_REQUEST_START,
  LOGIN_USER_REQUEST_SUCCESS,
  AUTHENTICATE_USER_REQUEST_SUCCESS,
  LOGIN_USER_REQUEST_FAIL,
  LOGOUT_USER_REQUEST_SUCCESS,
  LOGOUT_USER_REQUEST_FAIL,
  AUTHENTICATE_USER_REQUEST_START,
  AUTHENTICATE_USER_REQUEST_FAIL,
} from 'actions/sessionAction';

export const session: Reducer<any, any> = (
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    isLoading: false,
    currentUser: null,
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST_START:
    case LOGOUT_USER_REQUEST_START:
      return {
        ...state,
        errors: {},
        isLoading: true,
      };
    case LOGIN_USER_REQUEST_SUCCESS:
    case AUTHENTICATE_USER_REQUEST_SUCCESS: {
      return {
        ...state,
        currentUser: new User(action.response.data),
        isLoading: false,
        isAuthenticating: false,
        isAuthenticated: true,
      };
    }
    case LOGIN_USER_REQUEST_FAIL: {
      return {
        ...state,
        errors: action.data,
        isLoading: false,
      };
    }
    case LOGOUT_USER_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        currentUser: null,
      };
    }
    case LOGOUT_USER_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AUTHENTICATE_USER_REQUEST_START: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }
    case AUTHENTICATE_USER_REQUEST_FAIL: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    }
    default:
      return state;
  }
};
