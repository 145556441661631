import { Reducer } from 'redux';
import { camelizeKeys } from 'humps';

import {
  PaginationHeaders,
  TranslationsActions,
} from 'actions/translationsActions';

export interface Translation {
  key: string;
  translations: TranslationItem[];
  languages: string[];
}

export interface TranslationItem {
  id: string;
  value: string;
  locale: string;
  key: string;
}

export interface TranslationsState {
  readonly translations: Translation[];
  readonly meta: PaginationHeaders;
  readonly loading: boolean;
}

const initialTranslationState: TranslationsState = {
  translations: [],
  meta: { currentPage: 1, limit: 0, total: 0 },
  loading: false,
};

export const translations: Reducer<TranslationsState, TranslationsActions> = (
  state = initialTranslationState,
  action
) => {
  switch (action.type) {
    case 'TRANSLATIONS_GET_REQUEST': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'TRANSLATIONS_GET_SUCCESS': {
      const { xPage, xPerPage, xTotal } = camelizeKeys(
        action.response.headers
      ) as any;

      return {
        ...state,
        translations: action.response.data,
        meta: {
          currentPage: parseInt(xPage),
          total: parseInt(xTotal),
          limit: parseInt(xPerPage),
        },
        loading: false,
      };
    }

    default: {
      // return nothing
    }
  }

  return state;
};
