import { Action } from 'redux';

export const WORD_DELETE_REQUEST = 'WORD_DELETE_REQUEST';
export const WORD_DELETE_SUCCESS = 'WORD_DELETE_SUCCESS';
export const WORD_GET_REQUEST = 'WORD_GET_REQUEST';
export const WORD_GET_SUCCESS = 'WORD_GET_SUCCESS';
export const WORD_POST_REQUEST = 'WORD_POST_REQUEST';
export const WORD_POST_SUCCESS = 'WORD_POST_SUCCESS';
export const WORD_UNSUBSCRIBE = 'WORD_UNSUBSCRIBE';
export const WORD_UPDATE_REQUEST = 'WORD_UPDATE_REQUEST';
export const WORD_UPDATE_SUCCESS = 'WORD_UPDATE_SUCCESS';

export const WORDS_GET_REQUEST = 'WORDS_GET_REQUEST';
export const WORDS_GET_SUCCESS = 'WORDS_GET_SUCCESS';

export interface WordAttributes {
  id: number;
  name: string;
  description: string;
}

interface WordUpdateRequestAction extends Action<typeof WORD_UPDATE_REQUEST> {
  isLoading: boolean;
}

interface WordUpdateSuccessAction extends Action<typeof WORD_UPDATE_SUCCESS> {
  isLoading: boolean;
}

type WordUpdateActions = WordUpdateRequestAction | WordUpdateSuccessAction;

interface WordPostRequestAction extends Action<typeof WORD_POST_REQUEST> {
  isLoading: boolean;
}

interface WordPostSuccessAction extends Action<typeof WORD_POST_SUCCESS> {
  isLoading: boolean;
}

type WordPostActions = WordPostRequestAction | WordPostSuccessAction;

interface WordGetRequestAction extends Action<typeof WORD_GET_REQUEST> {
  isLoading: boolean;
}

interface WordGetSuccessAction extends Action<typeof WORD_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: WordAttributes;
  };
}

interface WordUnsubscribe extends Action<typeof WORD_UNSUBSCRIBE> {
  id: number;
}

type WordGetActions =
  | WordGetRequestAction
  | WordGetSuccessAction
  | WordUnsubscribe;

interface WordsGetRequestAction extends Action<typeof WORDS_GET_REQUEST> {
  loading: boolean;
}

interface WordsGetSuccessRequestAction
  extends Action<typeof WORDS_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: WordAttributes[];
  };
}

type WordsGetActions = WordsGetRequestAction | WordsGetSuccessRequestAction;

interface WordsDeleteRequestAction extends Action<typeof WORD_DELETE_REQUEST> {
  isLoading: boolean;
}

interface WordsDeleteSuccessAction extends Action<typeof WORD_DELETE_SUCCESS> {
  isLoading: boolean;
  id: number;
}

type WordDeleteActions = WordsDeleteRequestAction | WordsDeleteSuccessAction;

export type WordsActions =
  | WordDeleteActions
  | WordPostActions
  | WordsGetActions
  | WordGetActions
  | WordUpdateActions;
