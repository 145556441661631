import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import api from 'actions/utils/api';
import { requestStart, requestSuccess } from 'actions/utils/requestActions';
import { AppState } from 'reducers/rootReducer';

interface QuestionDetail {
  question: string;
  example?: string;
}

export interface CharacterExamQuestion {
  high: QuestionDetail;
  low: QuestionDetail;
  priority: number;
  question_type: string;
}

export const CHARACTER_EXAM_QUESTIONS_GET_REQUEST =
  'CHARACTER_EXAM_QUESTIONS_GET_REQUEST';
export const CHARACTER_EXAM_QUESTIONS_GET_SUCCESS =
  'CHARACTER_EXAM_QUESTIONS_GET_SUCCESS';

export interface CharacterExamQuestionsGetRequestAction
  extends Action<typeof CHARACTER_EXAM_QUESTIONS_GET_REQUEST> {
  loading: boolean;
}

export interface CharacterExamQuestionsSuccessRequestAction
  extends Action<typeof CHARACTER_EXAM_QUESTIONS_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: CharacterExamQuestion[];
  };
}

export type CharacterExamActions =
  | CharacterExamQuestionsGetRequestAction
  | CharacterExamQuestionsSuccessRequestAction;

export function getCharacterExamQuestions(): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart(CHARACTER_EXAM_QUESTIONS_GET_REQUEST));

    try {
      const response = await api.get('common/character_questions');
      dispatch(requestSuccess(CHARACTER_EXAM_QUESTIONS_GET_SUCCESS)(response));
    } catch (error) {
      console.log(error);
    }
  };
}
