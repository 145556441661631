import { Reducer } from 'redux';

import {
  BlogPostCategoriesActions,
  BLOG_POST_CATEGORIES_GET_REQUEST,
  BLOG_POST_CATEGORIES_GET_SUCCESS,
  BLOG_POST_CATEGORIES_UNSUBSCRIBE,
} from 'actions/blogPostCategories/types';

import { ResponseDataState } from './rootReducer';

const initialState = {
  data: null,
  isLoading: false,
};

export const blogPostCategories: Reducer<
  ResponseDataState,
  BlogPostCategoriesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case BLOG_POST_CATEGORIES_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BLOG_POST_CATEGORIES_GET_SUCCESS:
      return {
        ...state,
        data: action.response.data,
        isLoading: false,
      };
    case BLOG_POST_CATEGORIES_UNSUBSCRIBE:
      return { ...state };
    default: {
      return state;
    }
  }
};
