import { BlogPostAttributes } from 'actions/blog_posts/types';
import { blogPost } from 'reducers/blogPost';
import { User } from './User';

export class BlogPost {
  readonly data: BlogPostAttributes;

  constructor(data: BlogPostAttributes) {
    this.data = data;
  }

  id() {
    return this.data.id;
  }

  title() {
    return this.data.title;
  }

  categoryId() {
    return this.data.categoryId;
  }

  content() {
    return this.data.content;
  }

  shortenedContent() {
    return this.content().substring(0, 300) + '...';
  }

  createdAt() {
    return this.data.createdAt;
  }

  authorName() {
    return new User(this.data.author).login();
  }
}
