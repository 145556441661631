import { Action, Dispatch, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import api from 'actions/utils/api';
import { requestStart, requestSuccess } from 'actions/utils/requestActions';
import { AppState } from 'reducers/rootReducer';

export interface CharacterType {
  id: number;
  fullName: string;
  code: string;
}

export const CHARACTER_TYPES_GET_REQUEST = 'CHARACTER_TYPES_GET_REQUEST';
export const CHARACTER_TYPES_GET_SUCCESS = 'CHARACTER_TYPES_GET_SUCCESS';

export interface CharacterTypesGetRequestAction
  extends Action<typeof CHARACTER_TYPES_GET_REQUEST> {
  loading: true;
}

export interface CharacterTypesGetSuccessAction
  extends Action<typeof CHARACTER_TYPES_GET_SUCCESS> {
  response: { data: CharacterType[] };
  loading: false;
}

export type CharacterTypesActions =
  | CharacterTypesGetRequestAction
  | CharacterTypesGetSuccessAction;

export function getAllCharacterTypes(): ThunkAction<
  void,
  AppState,
  null,
  Action<CharacterTypesActions>
> {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart(CHARACTER_TYPES_GET_REQUEST));

    try {
      const response = await api.get('common/character_types');
      dispatch(requestSuccess(CHARACTER_TYPES_GET_SUCCESS)(response));
    } catch (error) {
      console.log(error);
    }
  };
}
