import { Reducer } from 'redux';

import { Faq } from 'models/Faq';
import { ResponseDataState } from './rootReducer';

import {
  FaqsActions,
  FAQS_GET_REQUEST,
  FAQS_GET_SUCCESS,
  FAQ_DELETE_REQUEST,
  FAQ_DELETE_SUCCESS,
} from 'actions/faqs/types';

export const faqs: Reducer<ResponseDataState, FaqsActions> = (
  state = {
    data: [],
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case FAQ_DELETE_REQUEST:
    case FAQS_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FAQS_GET_SUCCESS:
      return {
        ...state,
        data: action.response.data.map(attributes => new Faq(attributes)),
        isLoading: false,
      };
    case FAQ_DELETE_SUCCESS:
      return {
        ...state,
        data: [...state.data.filter((faq: Faq) => action.id !== faq.id())],
        isLoading: false,
      };

    default: {
      return state;
    }
  }
};
