import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { Language } from 'reducers/locales';
import { AppState } from 'reducers/rootReducer';
import { requestStart, requestSuccess } from 'actions/utils/requestActions';
import api from 'actions/utils/api';

export type I18nActions =
  | I18nSubscribeRequestAction
  | I18nSubscribeSuccessAction
  | I18nUnsubscribeAction;

export const TRANSLATIONS_SUBSCRIPTION_REQUEST =
  'TRANSLATIONS_SUBSCRIPTION_REQUEST';
export const TRANSLATIONS_SUBSCRIPTION_SUCCESS =
  'TRANSLATIONS_SUBSCRIPTION_SUCCESS';

export interface I18nSubscribeRequestAction
  extends Action<typeof TRANSLATIONS_SUBSCRIPTION_REQUEST> {
  loading: boolean;
}
export interface I18nSubscribeSuccessAction
  extends Action<typeof TRANSLATIONS_SUBSCRIPTION_SUCCESS> {
  loading: boolean;
  response: {
    data: {
      [key: string]: string;
    };
  };
}

export function subscribe(
  language: string
): ThunkAction<void, AppState, null, AnyAction> {
  return async (dispatch: ThunkDispatch<AppState, null, AnyAction>) => {
    dispatch(requestStart(TRANSLATIONS_SUBSCRIPTION_REQUEST));
    dispatch(availableLocales());

    try {
      const response = await api.get(`common/translations/yaml/${language}`);
      dispatch(requestSuccess(TRANSLATIONS_SUBSCRIPTION_SUCCESS)(response));
    } catch (e) {
      console.log(e);
    }
  };
}

export const TRANSLATIONS_UNSUBSCRIBE = 'TRANSLATIONS_UNSUBSCRIBE';

export interface I18nUnsubscribeAction
  extends Action<typeof TRANSLATIONS_UNSUBSCRIBE> {
  loading: boolean;
  data: {};
}

export function unsubscribe() {
  return {
    type: TRANSLATIONS_UNSUBSCRIBE,
  };
}

export const GET_AVAILABLE_LANGUAGES_REQUEST_START =
  'GET_AVAILABLE_LANGUAGES_REQUEST_START';
export const GET_AVAILABLE_LANGUAGES_REQUEST_SUCCESS =
  'GET_AVAILABLE_LANGUAGES_REQUEST_SUCCESS';

export type AvailableLanguagesActions =
  | GetAvailableLanguageRequestStart
  | GetAvailableLanguageRequestSuccess;

interface GetAvailableLanguageRequestStart
  extends Action<typeof GET_AVAILABLE_LANGUAGES_REQUEST_START> {
  response: { data: [] };
  loading: true;
}

interface GetAvailableLanguageRequestSuccess
  extends Action<typeof GET_AVAILABLE_LANGUAGES_REQUEST_SUCCESS> {
  response: { data: Language[] };
  loading: false;
}

export function availableLocales(): ThunkAction<
  void,
  AppState,
  null,
  AnyAction
> {
  return async (dispatch: ThunkDispatch<AppState, null, AnyAction>) => {
    dispatch(requestStart(GET_AVAILABLE_LANGUAGES_REQUEST_START));

    try {
      const response = await api.get('common/languages');
      dispatch(
        requestSuccess(GET_AVAILABLE_LANGUAGES_REQUEST_SUCCESS)(response)
      );
    } catch (e) {
      console.log(e);
    }
  };
}
