import { Reducer } from 'redux';
import { camelizeKeys } from 'humps';

import {
  CHARACTER_OPINION_TEMPLATE_GET_REQUEST,
  CHARACTER_OPINION_TEMPLATE_GET_SUCCESS,
  CHARACTER_OPINION_TEMPLATE_POST_REQUEST,
  CHARACTER_OPINION_TEMPLATE_POST_SUCCESS,
  CHARACTER_OPINION_TEMPLATES_GET_REQUEST,
  CHARACTER_OPINION_TEMPLATES_GET_SUCCESS,
  CHARACTER_OPINION_TEMPLATE_UNSUBSCRIBE,
  CHARACTER_OPINION_TEMPLATE_DELETE_REQUEST,
  CHARACTER_OPINION_TEMPLATE_DELETE_SUCCESS,
  REMOVE_CHARACTER_OPINION_TEMPLATE,
  CharacterOpinionTemplate,
  CharacterOpinionTemplateActions,
  CharacterOpinionTemplatesActions,
} from 'actions/characterOpinionTemplatesActions';
import { PaginationHeaders } from 'actions/translationsActions';

export interface CharacterOpinionTemplatesState {
  readonly data: CharacterOpinionTemplate[];
  readonly meta: PaginationHeaders;
  readonly loading: boolean;
}

const initialCharacterOpinionTemplatesState: CharacterOpinionTemplatesState = {
  data: [],
  meta: { currentPage: 1, limit: 0, total: 0 },
  loading: false,
};

export const characterOpinionTemplates: Reducer<
  CharacterOpinionTemplatesState,
  CharacterOpinionTemplatesActions
> = (state = initialCharacterOpinionTemplatesState, action) => {
  switch (action.type) {
    case CHARACTER_OPINION_TEMPLATES_GET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHARACTER_OPINION_TEMPLATES_GET_SUCCESS: {
      const { xPage, xPerPage, xTotal } = camelizeKeys(
        action.response.headers
      ) as any;

      return {
        ...state,
        meta: {
          currentPage: parseInt(xPage),
          total: parseInt(xTotal),
          limit: parseInt(xPerPage),
        },
        data: action.response.data,
        loading: false,
      };
    }

    case REMOVE_CHARACTER_OPINION_TEMPLATE: {
      return {
        ...state,
        ...state.data.filter(template => action.id != template.id),
      };
    }

    default: {
      // return nothing
    }
  }

  return state;
};

export interface CharacterOpinionTemplateState {
  readonly data: CharacterOpinionTemplate | null;
  readonly loading: boolean;
}

const initialCharacterOpinionTemplateState: CharacterOpinionTemplateState = {
  data: null,
  loading: false,
};

export const characterOpinionTemplate: Reducer<
  CharacterOpinionTemplateState,
  CharacterOpinionTemplateActions
> = (state = initialCharacterOpinionTemplateState, action) => {
  switch (action.type) {
    case CHARACTER_OPINION_TEMPLATE_GET_REQUEST ||
      CHARACTER_OPINION_TEMPLATE_POST_REQUEST ||
      CHARACTER_OPINION_TEMPLATE_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case CHARACTER_OPINION_TEMPLATE_GET_SUCCESS ||
      CHARACTER_OPINION_TEMPLATE_POST_SUCCESS ||
      CHARACTER_OPINION_TEMPLATE_DELETE_SUCCESS: {
      return {
        ...state,
        data: action.response.data,
        loading: false,
      };
    }

    case CHARACTER_OPINION_TEMPLATE_UNSUBSCRIBE: {
      return {
        ...state,
        ...initialCharacterOpinionTemplateState,
      };
    }

    default: {
      // return nothing
    }
  }

  return state;
};
