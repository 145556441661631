import { Reducer } from 'redux';

import { ResponseDataState } from './rootReducer';

import {
  WordsActions,
  WORDS_GET_REQUEST,
  WORDS_GET_SUCCESS,
  WORD_DELETE_REQUEST,
  WORD_DELETE_SUCCESS,
} from 'actions/words/types';

export const words: Reducer<ResponseDataState, WordsActions> = (
  state = {
    data: [],
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case WORD_DELETE_REQUEST:
    case WORDS_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case WORDS_GET_SUCCESS:
      return {
        ...state,
        data: action.response.data,
        isLoading: false,
      };
    case WORD_DELETE_SUCCESS:
      return {
        ...state,
        data: [...state.data.filter(word => action.id !== word.id)],
        isLoading: false,
      };

    default: {
      return state;
    }
  }
};
