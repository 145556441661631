import { Action } from 'redux';

export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';

export interface setCurrentLocale extends Action<typeof SET_CURRENT_LOCALE> {
  locale: string;
}

export function setCurrentLocale(locale: string) {
  return {
    type: SET_CURRENT_LOCALE,
    locale,
  };
}