import { Reducer } from 'redux';

import {
  VariablesActions,
  VARIABLES_GET_REQUEST,
  VARIABLES_GET_SUCCESS,
} from 'actions/variablesActions';

interface VariablesState {
  readonly data: string[];
  readonly loading: boolean;
}

const initialCharacterTypesState: VariablesState = {
  data: [],
  loading: false,
};

export const variables: Reducer<VariablesState, VariablesActions> = (
  state = initialCharacterTypesState,
  action
) => {
  switch (action.type) {
    case VARIABLES_GET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VARIABLES_GET_SUCCESS: {
      return {
        ...state,
        data: action.response.data,
        loading: false,
      };
    }
    default: {
      // return nothing
    }
  }

  return state;
};
