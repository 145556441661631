import { Reducer } from 'redux';

import {
  WORD_GET_REQUEST,
  WORD_GET_SUCCESS,
  WORD_UNSUBSCRIBE,
  WORD_POST_REQUEST,
  WORD_POST_SUCCESS,
  WORD_UPDATE_REQUEST,
  WORD_UPDATE_SUCCESS,
  WordsActions,
} from 'actions/words/types';

import { ResponseDataState } from './rootReducer';

const initialState = {
  data: null,
  isLoading: false,
};

export const word: Reducer<ResponseDataState, WordsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case WORD_GET_REQUEST:
    case WORD_UPDATE_REQUEST:
    case WORD_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case WORD_UPDATE_SUCCESS:
    case WORD_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WORD_GET_SUCCESS:
      return {
        ...state,
        data: action.response.data,
        isLoading: false,
      };
    case WORD_UNSUBSCRIBE:
      return { ...state, ...initialState };
    default: {
      return state;
    }
  }
};
