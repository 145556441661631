import { Action } from 'redux';

export const BLOG_POST_POST_REQUEST = 'BLOG_POST_POST_REQUEST';
export const BLOG_POST_POST_SUCCESS = 'BLOG_POST_POST_SUCCESS';
export const BLOG_POST_GET_REQUEST = 'BLOG_POST_GET_REQUEST';
export const BLOG_POST_GET_SUCCESS = 'BLOG_POST_GET_SUCCESS';
export const BLOG_POST_UNSUBSCRIBE = 'BLOG_POST_UNSUBSCRIBE';
export const BLOG_POSTS_GET_REQUEST = 'BLOG_POSTS_GET_REQUEST';
export const BLOG_POSTS_GET_SUCCESS = 'BLOG_POSTS_GET_SUCCESS';
export const BLOG_POST_DELETE_REQUEST = 'BLOG_POSTS_DELETE_REQUEST';
export const BLOG_POST_DELETE_SUCCESS = 'BLOG_POSTS_DELETE_SUCCESS';
export const BLOG_POST_UPDATE_REQUEST = 'BLOG_POST_UPDATE_REQUEST';
export const BLOG_POST_UPDATE_SUCCESS = 'BLOG_POST_UPDATE_SUCCESS';

export interface BlogPostAttributes {
  id: number;
  title: string;
  content: string;
  createdAt: string;
  categoryId: number;
  author: {
    name: string;
    email: string;
  };
}

interface BlogPostUpdateRequestAction
  extends Action<typeof BLOG_POST_UPDATE_REQUEST> {
  isLoading: boolean;
}

interface BlogPostUpdateSuccessAction
  extends Action<typeof BLOG_POST_UPDATE_SUCCESS> {
  isLoading: boolean;
}

type BlogPostUpdateActions =
  | BlogPostUpdateRequestAction
  | BlogPostUpdateSuccessAction;

interface BlogPostPostRequestAction
  extends Action<typeof BLOG_POST_POST_REQUEST> {
  isLoading: boolean;
}

interface BlogPostPostSuccessAction
  extends Action<typeof BLOG_POST_POST_SUCCESS> {
  isLoading: boolean;
}

type BlogPostPostActions =
  | BlogPostPostRequestAction
  | BlogPostPostSuccessAction;

interface BlogPostGetRequestAction
  extends Action<typeof BLOG_POST_GET_REQUEST> {
  isLoading: boolean;
}

interface BlogPostGetSuccessAction
  extends Action<typeof BLOG_POST_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: BlogPostAttributes;
  };
}

interface BlogPostUnsubscribe extends Action<typeof BLOG_POST_UNSUBSCRIBE> {
  id: number;
}

type BlogPostGetActions =
  | BlogPostGetRequestAction
  | BlogPostGetSuccessAction
  | BlogPostUnsubscribe;

interface BlogPostsGetRequestAction
  extends Action<typeof BLOG_POSTS_GET_REQUEST> {
  loading: boolean;
}

interface BlogPostsGetSuccessRequestAction
  extends Action<typeof BLOG_POSTS_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: BlogPostAttributes[];
  };
}

type BlogPostsGetActions =
  | BlogPostsGetRequestAction
  | BlogPostsGetSuccessRequestAction;

interface BlogPostsDeleteRequestAction
  extends Action<typeof BLOG_POST_DELETE_REQUEST> {
  isLoading: boolean;
}

interface BlogPostsDeleteSuccessAction
  extends Action<typeof BLOG_POST_DELETE_SUCCESS> {
  isLoading: boolean;
  id: number;
}

type BlogPostDeleteActions =
  | BlogPostsDeleteRequestAction
  | BlogPostsDeleteSuccessAction;

export type BlogPostsActions =
  | BlogPostDeleteActions
  | BlogPostPostActions
  | BlogPostsGetActions
  | BlogPostGetActions
  | BlogPostUpdateActions;
