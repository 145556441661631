import { Action } from 'redux';

export const FAQ_POST_REQUEST = 'FAQ_POST_REQUEST';
export const FAQ_POST_SUCCESS = 'FAQ_POST_SUCCESS';
export const FAQ_GET_REQUEST = 'FAQ_GET_REQUEST';
export const FAQ_GET_SUCCESS = 'FAQ_GET_SUCCESS';
export const FAQ_UNSUBSCRIBE = 'FAQ_UNSUBSCRIBE';
export const FAQS_GET_REQUEST = 'FAQS_GET_REQUEST';
export const FAQS_GET_SUCCESS = 'FAQS_GET_SUCCESS';
export const FAQ_DELETE_REQUEST = 'FAQ_DELETE_REQUEST';
export const FAQ_DELETE_SUCCESS = 'FAQ_DELETE_SUCCESS';
export const FAQ_UPDATE_REQUEST = 'FAQ_UPDATE_REQUEST';
export const FAQ_UPDATE_SUCCESS = 'FAQ_UPDATE_SUCCESS';

export interface FaqAttributes {
  id: number;
  title: string;
  description: string;
  priority: number;
}

interface FaqUpdateRequestAction extends Action<typeof FAQ_UPDATE_REQUEST> {
  isLoading: boolean;
}

interface FaqUpdateSuccessAction extends Action<typeof FAQ_UPDATE_SUCCESS> {
  isLoading: boolean;
}

type FaqUpdateActions = FaqUpdateRequestAction | FaqUpdateSuccessAction;

interface FaqPostRequestAction extends Action<typeof FAQ_POST_REQUEST> {
  isLoading: boolean;
}

interface FaqPostSuccessAction extends Action<typeof FAQ_POST_SUCCESS> {
  isLoading: boolean;
}

type FaqPostActions = FaqPostRequestAction | FaqPostSuccessAction;

interface FaqGetRequestAction extends Action<typeof FAQ_GET_REQUEST> {
  isLoading: boolean;
}

interface FaqGetSuccessAction extends Action<typeof FAQ_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: FaqAttributes;
  };
}

interface FaqUnsubscribe extends Action<typeof FAQ_UNSUBSCRIBE> {
  id: number;
}

type FaqGetActions = FaqGetRequestAction | FaqGetSuccessAction | FaqUnsubscribe;

interface FaqsGetRequestAction extends Action<typeof FAQS_GET_REQUEST> {
  loading: boolean;
}

interface FaqsGetSuccessRequestAction extends Action<typeof FAQS_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: FaqAttributes[];
  };
}

type FaqsGetActions = FaqsGetRequestAction | FaqsGetSuccessRequestAction;

interface FaqsDeleteRequestAction extends Action<typeof FAQ_DELETE_REQUEST> {
  isLoading: boolean;
}

interface FaqsDeleteSuccessAction extends Action<typeof FAQ_DELETE_SUCCESS> {
  isLoading: boolean;
  id: number;
}

type FaqDeleteActions = FaqsDeleteRequestAction | FaqsDeleteSuccessAction;

export type FaqsActions =
  | FaqDeleteActions
  | FaqPostActions
  | FaqsGetActions
  | FaqGetActions
  | FaqUpdateActions;
