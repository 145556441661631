import { Reducer } from 'redux';

import {
  setCurrentLocale,
  SET_CURRENT_LOCALE,
} from 'actions/currentLocaleActions';

// determines the default language for app
export const DEFAULT_LOCALE = 'pl';

export interface CurrentLocaleState {
  currentLocale: string;
}

const initialCurrentLocaleState = {
  currentLocale: DEFAULT_LOCALE,
};

export const currentLocale: Reducer<CurrentLocaleState, setCurrentLocale> = (
  state = initialCurrentLocaleState,
  action
) => {
  switch (action.type) {
    case SET_CURRENT_LOCALE: {
      return {
        currentLocale: action.locale,
      };
    }
    default:
      return state;
  }
};
