import * as React from 'react';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import * as I18n from './src/actions/i18nActions';
import { rootReducer, AppState } from './src/reducers/rootReducer';
import { initialI18nState } from 'reducers/i18n';
import { DEFAULT_LOCALE } from 'reducers/currentLocale';

export const I18nContext = React.createContext({
  ...initialI18nState,
  currentLocale: DEFAULT_LOCALE,
  availableLanguages: [],
});

export default ({ element }: { element: Element }) => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );

  return (
    <Provider store={store}>
      <AppWrapper>{element}</AppWrapper>
    </Provider>
  );
};

const AppWrapper = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const [
    i18n,
    currentLocale,
    availableLanguages,
  ] = useSelector((state: AppState) => [
    state.i18n,
    state.currentLocale.currentLocale,
    state.availableLanguages.data,
  ]);

  React.useEffect(() => {
    dispatch(I18n.subscribe(currentLocale));
    return () => {
      dispatch(I18n.unsubscribe());
    };
  }, [currentLocale]);

  const i18nContextData = {
    ...i18n,
    availableLanguages,
    currentLocale,
  };

  return (
    <I18nContext.Provider value={i18nContextData}>
      {children}
    </I18nContext.Provider>
  );
};
