import { Reducer } from 'redux';

import {
  AvailableLanguagesActions,
  GET_AVAILABLE_LANGUAGES_REQUEST_START,
  GET_AVAILABLE_LANGUAGES_REQUEST_SUCCESS,
} from 'actions/i18nActions';

import { Language } from './locales';

interface AvailableLanguagesState {
  readonly data: Language[];
  readonly loading: boolean;
}

const initialAvailableLanguageState = {
  data: [],
  loading: false,
};

export const availableLanguages: Reducer<
  AvailableLanguagesState,
  AvailableLanguagesActions
> = (state = initialAvailableLanguageState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_LANGUAGES_REQUEST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_AVAILABLE_LANGUAGES_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.response.data,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
