/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import 'bootstrap/dist/css/bootstrap.css'
import wrapWithProvider from './entry'
export const wrapRootElement = wrapWithProvider
