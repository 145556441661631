import { Reducer } from 'redux';

import {
  BLOG_POST_GET_REQUEST,
  BLOG_POST_GET_SUCCESS,
  BLOG_POST_UNSUBSCRIBE,
  BLOG_POST_POST_REQUEST,
  BLOG_POST_POST_SUCCESS,
  BLOG_POST_UPDATE_REQUEST,
  BLOG_POST_UPDATE_SUCCESS,
  BlogPostsActions,
} from 'actions/blog_posts/types';

import { BlogPost } from 'models/BlogPost';
import { ResponseDataState } from './rootReducer';

const initialState = {
  data: null,
  isLoading: false,
};

export const blogPost: Reducer<ResponseDataState, BlogPostsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case BLOG_POST_GET_REQUEST:
    case BLOG_POST_UPDATE_REQUEST:
    case BLOG_POST_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BLOG_POST_UPDATE_SUCCESS:
    case BLOG_POST_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case BLOG_POST_GET_SUCCESS:
      return {
        ...state,
        data: new BlogPost(action.response.data),
        isLoading: false,
      };
    case BLOG_POST_UNSUBSCRIBE:
      return initialState;
    default: {
      return state;
    }
  }
};
