import { Action } from 'redux';

export const BLOG_POST_CATEGORIES_GET_REQUEST =
  'BLOG_POST_CATEGORIES_GET_REQUEST';
export const BLOG_POST_CATEGORIES_GET_SUCCESS =
  'BLOG_POST_CATEGORIES_GET_SUCCESS';
export const BLOG_POST_CATEGORIES_UNSUBSCRIBE =
  'BLOG_POST_CATEGORIES_UNSUBSCRIBE';

interface BlogPostCategoriesGetRequestAction
  extends Action<typeof BLOG_POST_CATEGORIES_GET_REQUEST> {
  isLoading: boolean;
}

interface BlogPostCategoriesGetSuccessAction
  extends Action<typeof BLOG_POST_CATEGORIES_GET_SUCCESS> {
  loading: boolean;
  response: {
    data: Array<{
      id: number;
      name: string;
    }>;
  };
}

interface BlogPostCategoriesUnsubscribe
  extends Action<typeof BLOG_POST_CATEGORIES_UNSUBSCRIBE> {
  id: number;
}

type BlogPostCategoriesGetActions =
  | BlogPostCategoriesGetRequestAction
  | BlogPostCategoriesGetSuccessAction
  | BlogPostCategoriesUnsubscribe;

export type BlogPostCategoriesActions = BlogPostCategoriesGetActions;
