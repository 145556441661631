import api from 'actions/utils/api';
import { requestStart, requestSuccess } from 'actions/utils/requestActions';
import { addNotificationMessage } from './notificationMessagesActions';
import { Dispatch, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'reducers/rootReducer';

export const LOGIN_USER_REQUEST_START = 'LOGIN_USER_REQUEST_START';
export const LOGIN_USER_REQUEST_SUCCESS = 'LOGIN_USER_REQUEST_SUCCESS';
export const LOGIN_USER_REQUEST_FAIL = 'LOGIN_USER_REQUEST_FAIL';

export interface LoginUserRequestSuccessAction
  extends Action<typeof LOGIN_USER_REQUEST_SUCCESS> {
  response: unknown;
}

export type LoginUserActions =
  | Action<typeof LOGIN_USER_REQUEST_START>
  | LoginUserRequestSuccessAction;

export function loginUser({
  login,
  password,
}: {
  login: string;
  password: string;
}): ThunkAction<void, AppState, null, Action<LoginUserActions>> {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart(LOGIN_USER_REQUEST_START));

    try {
      const response = await api.post('common/session', { login, password });
      dispatch(requestSuccess(LOGIN_USER_REQUEST_SUCCESS)(response));
    } catch (error) {
      dispatch(addNotificationMessage(error));
    }
  };
}

export const LOGOUT_USER_REQUEST_SUCCESS = 'LOGOUT_USER_REQUEST_SUCCESS';
export const LOGOUT_USER_REQUEST_START = 'LOGOUT_USER_REQUEST_START';
export const LOGOUT_USER_REQUEST_FAIL = 'LOGOUT_USER_REQUEST_FAIL';

export interface LogoutUserRequestSuccessAction
  extends Action<typeof LOGOUT_USER_REQUEST_SUCCESS> {
  response: unknown;
}

export type LogoutUserActions =
  | Action<typeof LOGOUT_USER_REQUEST_START>
  | LogoutUserRequestSuccessAction;

export function logoutUser(): ThunkAction<
  void,
  AppState,
  null,
  Action<LogoutUserActions>
> {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart(LOGOUT_USER_REQUEST_START));

    try {
      const response = await api.delete('common/session');
      dispatch(requestSuccess(LOGOUT_USER_REQUEST_SUCCESS)(response));
    } catch (error) {
      dispatch(addNotificationMessage(error));
    }
  };
}

export const AUTHENTICATE_USER_REQUEST_SUCCESS =
  'AUTHENTICATE_USER_REQUEST_SUCCESS';
export const AUTHENTICATE_USER_REQUEST_START =
  'AUTHENTICATE_USER_REQUEST_START';
export const AUTHENTICATE_USER_REQUEST_FAIL = 'AUTHENTICATE_USER_REQUEST_FAIL';

interface AuthenticateUserRequestSuccessAction
  extends Action<typeof AUTHENTICATE_USER_REQUEST_SUCCESS> {
  response: unknown;
}

export type AuthenticateUserActions =
  | Action<typeof AUTHENTICATE_USER_REQUEST_START>
  | AuthenticateUserRequestSuccessAction;

export function authenticateUser() {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart(AUTHENTICATE_USER_REQUEST_START));

    try {
      const response = await api.get('common/session');
      dispatch(requestSuccess(AUTHENTICATE_USER_REQUEST_SUCCESS)(response));
    } catch (error) {
      dispatch(addNotificationMessage(error));
    }
  };
}
