import { Reducer } from 'redux';

import { LanguageActions } from 'actions/localesActions';

export interface Language {
  key: string;
  value: string;
  localizer: string;
  id: number;
}

export interface LocalesState {
  readonly locales: Language[];
  readonly loading: boolean;
}

const initialLocalesState: LocalesState = {
  locales: [],
  loading: false
};

export const locales: Reducer<LocalesState, LanguageActions> = (
  state = initialLocalesState,
  action
) => {
  switch (action.type) {
    case 'LANGUAGES_GET_REQUEST': {
      return {
        ...state,
        loading: true
      };
    }
    case 'LANGUAGES_GET_SUCCESS': {
      return {
        ...state,
        locales: action.response.data,
        loading: false
      };
    }
    default: {
      // return nothing
    }
  }

  return state;
};
