import { merge } from 'lodash';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { v1Path } from 'actions/utils/v1Path';

type RequestParams = Object | null;
type RestMethods = 'post' | 'get' | 'patch' | 'put' | 'delete';

// For development usage please set proxy on your local Nginx server
// RAILS port - 3000, REACT port - 3001, host - localhost
const getApiUrl = () => `${v1Path()}/api/v1`;

const transformResponse = [
  (data?: string) => (data && camelizeKeys(JSON.parse(data))) || {},
];

function request(
  type: RestMethods,
  path: string,
  data: Object | null,
  headers: Object | null
) {
  const formattedData = data ? decamelizeKeys(data) : null;
  const params =
    type === 'get' ? { params: formattedData } : { data: formattedData };

  const url = `${getApiUrl()}/${path}`;

  return axios(
    merge({ url, method: type, headers, transformResponse }, params, {
      withCredentials: true,
    })
  );
}

export default {
  post: (path: string, data: RequestParams = null, headers = null) =>
    request('post', path, data, headers),
  get: (path: string, data: RequestParams = null, headers = null) =>
    request('get', path, data, headers),
  patch: (path: string, data: RequestParams = null, headers = null) =>
    request('patch', path, data, headers),
  put: (path: string, data: RequestParams = null, headers = null) =>
    request('put', path, data, headers),
  delete: (path: string, data: RequestParams = null, headers = null) =>
    request('delete', path, data, headers),
};
