import { Reducer } from 'redux';

import {
  FAQ_GET_REQUEST,
  FAQ_GET_SUCCESS,
  FAQ_UNSUBSCRIBE,
  FAQ_POST_REQUEST,
  FAQ_POST_SUCCESS,
  FAQ_UPDATE_REQUEST,
  FAQ_UPDATE_SUCCESS,
  FaqsActions,
} from 'actions/faqs/types';

import { Faq } from 'models/Faq';
import { ResponseDataState } from './rootReducer';

const initialState = {
  data: null,
  isLoading: false,
};

export const faq: Reducer<ResponseDataState, FaqsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FAQ_GET_REQUEST:
    case FAQ_UPDATE_REQUEST:
    case FAQ_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FAQ_UPDATE_SUCCESS:
    case FAQ_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FAQ_GET_SUCCESS:
      return {
        ...state,
        data: new Faq(action.response.data),
        isLoading: false,
      };
    case FAQ_UNSUBSCRIBE:
      return { ...state, ...initialState };
    default: {
      return state;
    }
  }
};
