import { AxiosResponse } from 'axios';

export const requestStart = (type: string) => ({
  type,
});

export const requestSuccess = (type: string, id?: number) => <T>(
  response: AxiosResponse<T>
) => ({
  type,
  id,
  response,
});

export const requestFail = (type: string) => ({
  type,
});
