import { Reducer } from 'redux';
import * as Uuid from 'uuid';

import {
  ADD_NOTIFICATION_MESSAGE,
  REMOVE_NOTIFICATION_MESSAGE,
  SET_NOTIFICATION_DISPLAYED,
  NotificationMessagesActions,
} from 'actions/notificationMessagesActions';

export interface NotificationMessage {
  readonly id: string;
  readonly type: string;
  readonly text: string;
  readonly displayed: boolean;
}

type NotificationMessagesState = NotificationMessage[];

export const notificationMessages: Reducer<
  NotificationMessagesState,
  NotificationMessagesActions
> = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATION_MESSAGE: {
      return [
        {
          id: Uuid.v4(),
          displayed: false,
          type: action.message.type,
          text: action.message.text,
        },
        ...state,
      ];
    }

    case SET_NOTIFICATION_DISPLAYED: {
      return state.map(notification => {
        if (notification.id === action.id) {
          return { ...notification, displayed: true };
        }
        return notification;
      });
    }

    case REMOVE_NOTIFICATION_MESSAGE:
      return state.filter(({ id }) => id !== action.id);
    default:
      return state;
  }
};
