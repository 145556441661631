import { combineReducers } from 'redux';

import { availableLanguages } from './availableLanguages';
import { blogPost } from './blogPost';
import { blogPosts } from './blogPosts';
import { blogPostCategories } from './blogPostCategories';
import { characterExam } from './characterExam';
import {
  characterOpinionTemplates,
  characterOpinionTemplate,
} from './characterOpinionTemplates';
import { characterTypes } from './characterTypes';
import { currentLocale } from './currentLocale';
import { faq } from './faq';
import { faqs } from './faqs';
import { i18n } from './i18n';
import { locales } from './locales';
import { notificationMessages } from './notificationMessages';
import { session } from './session';
import { translationItem } from './translationItem';
import { translations } from './translations';
import { variables } from './variables';
import { words } from './words';
import { word } from './word';

export interface ResponseDataState {
  readonly data: any;
  readonly isLoading: boolean;
}

export const rootReducer = combineReducers({
  availableLanguages,
  blogPost,
  blogPostCategories,
  blogPosts,
  characterExam,
  characterOpinionTemplate,
  characterOpinionTemplates,
  characterTypes,
  currentLocale,
  faq,
  faqs,
  i18n,
  locales,
  notificationMessages,
  session,
  translationItem,
  translations,
  variables,
  words,
  word,
});

export type AppState = ReturnType<typeof rootReducer>;
